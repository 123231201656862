// import externals
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'node_modules/react-modal-video/scss/modal-video.scss';

// import locals
@import './typography';
@import './fonts';
@import './background';

// custom css
input[type="color"] {
	-webkit-appearance: none;
	border: none;
  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  &::-webkit-color-swatch {
    border: none;
  }
}

// range slider css
.rangeslider__handle-label {
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 31px;
  font-size: 14px;
  font-weight: 500;
}
.rangeslider-horizontal {
  height: 1px;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: #707070;
}
.rangeslider {
  background: #DEDEDE;
  box-shadow: none;
  margin-top: 54px;
}
.rangeslider-horizontal .rangeslider__handle {
  width: 20px;
  height: 20px;
  &::after{
    display: none;
  }
}
.rangeslider .rangeslider__handle {
  border: 1px solid #707070;
  box-shadow: none;
}

// switch css
.switchCheckBox{
  height: 44px;
  label{
    user-select: none;
    width: 65px;
    height: 100%;
    background: #fff;
    display: block;
    border: 1px solid #DEDEDE;
    position: relative;
    cursor: pointer;
    &::before{
      content: '';
      position: absolute;
      left: 3px;
      top: 3px;
      width: 36px;
      height: 36px;
      background: #DEDEDE;
      border-radius: 50%;
      box-shadow: 0 2px 15px 1px rgb(253 80 1 / 30%);
      transition: all 0.3s cubic-bezier(1, 0.04, 0.39, 1.26) 0s;
    }
  }
  input:checked ~ label{
    &::before{
      background: #FD5001;
      left: calc(100% - 39px);
    }
  }
}

.srv-validation-message{
  font-size: 12px;
  color: #FD5001;
}

@layer components {
  .btn {
    @apply w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm;
  }

  .btn-red {
    @apply btn border-transparent bg-red-600 text-white hover:bg-red-700 focus:ring-red-500;
  }

  .btn-default {
    @apply btn border-gray-300 bg-white text-gray-700 hover:bg-gray-50 focus:ring-indigo-500;
  }

  .formControl {
    @apply w-full px-4 py-3 leading-5 border rounded-md focus:outline-none focus:ring-1 focus:border-blue-400 text-xs;
  }
}

.templateControllerTab{
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

// switch css
.checkBox{
  input{
    display: none;
  }
  label{
    user-select: none;
    display: block;
    position: relative;
    cursor: pointer;
    padding-left: 27px;
    &::before{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      border: 1px solid #DEDEDE;
      background: #fff;
    }
    &::after{
      content: '';
      position: absolute;
      left: 8px;
      top: 4px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      opacity: 0;
    }
  }
  input:checked ~ label{
    &::before{
      background: #5c90f1;
    }
    &::after{
      opacity: 1;
    }
  }
}

//multilinetip css fot tooltip
.multilinetip{
  width: 300px;
  max-width: 100%;
}

.inputImageLabel{
  height: 47px;
  width: 100%;
  position: relative;
  border-radius: 0.375rem;
  display: block;
  background: #fff;
  cursor: pointer;
  img{
    position: absolute;
    left: 0;
    top: 0;
    width: auto;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    overflow: hidden;
  }
  input{
    display: none;
  }
}